.SelectionTableDiv {
    align-content: center;
}
  
.SelectionTable {
    margin-top: .5em;
    margin-bottom: .5em;
    margin-left: 10%;
    margin-right: 10%;
    width: 80%;
    table-layout: fixed;
    border-collapse: collapse;
    border-top-left-radius: .3em;
    border-bottom-left-radius: .3em;
    border-bottom-right-radius: .3em;
}

.SelectionTable td {
    border: 1.2px solid rgb(51, 51, 51);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 1.2em;
    border-collapse: collapse;
}

.SelectionTable tr {
    margin: 0em;
    padding: 0em;
    min-width: 75px;
}

.SelectionTable PageNavBar {
    border: none;
}

.AddNewRowRow {
    padding-top: .1em;
    padding-bottom: .1em;
    padding-left: .1em;
    color: #005CF5;
    text-decoration: underline;
    cursor: pointer;
}  

.SelectReadOnlyTableColumn {
    padding-top: .1em;
    padding-bottom: .1em;
    padding-left: .1em;
    color: #005CF5;
    text-decoration: underline;
    cursor: pointer;
}

.tableNavBar .PageNavBar {
    border: unset;
    border-radius: unset;
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
    table-layout: unset;
  }

  .PageNavBar tr {
      border: none;
  }

  .PageNavBar td {
      border: none;
  }

  .PageNavBarButton td {
    display: inline-block;
    align-content: center;
    width: 10%;
    min-width: 50px;
    min-height: 1em;
    padding-top: .1em;
    border: none;
    outline: none;
  }
  
  .PageNavBarButton .slt_button_clear:focus {
    outline: none;
  }
  
  .PageNavBarButton .slt_button_clear {
    width: 20%;
    min-width: 50px;
    vertical-align: bottom;
    padding-top: 5px;
  }
  
  .PageNavBarMsg {
    align-content: center;
    vertical-align: bottom;
  }
  
  .PageNavBar {
    margin-top: .5em;
    margin-bottom: .5em;
  }  