.slt_info_tablet {
    width: 100%;
    height: auto;
    min-width: 320px;
    max-width: 1000px;
    min-height: 750px;
    max-height: 1000px;
    position: flex;
    margin: 0;
    padding: 0;
    align-items: center;
    align-content: center;
}

.slt_tablet_inlay {
    position: flex;
    width: 90%;
    height: auto;
    min-width: 320px;
    max-width: 750px;
/*    min-height: 1010px; */
    box-sizing: border-box;
    border-radius: 5px;
    background-color: rgba(80,80,80,.8);
    box-shadow: 0 3px 5px rgba(51, 51, 51, .90);
    align-items: center;
    margin: 5%;
    margin-top: 70px;
    margin-bottom: 70px;
}

.slt_info_slogan {
    position: relative;
    width: 90%;
    margin-left: 5%;
    color: rgba(255,255,255,.7);
    font-size: 1.5em;
    line-height: 1.1em;
    height: 3em;
    align-content: center;
    margin-left: 5%;
} 

.slt_info_text {
    position: relative;
    width: 95%;
    margin-left: 3%;
    margin-right: 3%;
    color: rgba(255,255,255,.6);
    height: auto;
    font-size: 1.25em;
    line-height: 1.1em;
    text-align:left;
    align-content: left;
    justify-content: left;
    white-space: pre-wrap;
    padding-top: 10px;
    padding-bottom: 10px;
}

.slt_bullet_list {
    position: relative;
    width: 90%;
    margin-left: 5%;
    color: rgba(255,255,255,.6);
    font-size: 1.25em;
    text-align: left;
    align-content: left;
    justify-content: left;
    list-style: circle inside;
}

.slt_bullet_point {
    width: 100%;
    margin-left: 10%;
    margin-top: 10px;
}