/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/* END Reset Script */

/* 
 * SLTEigengrau CSS Start
 * Written by: Robert Blanch
 *
 * (C) 2024 Stony Lane Tech LLC
 */
body {
  height: 100%;
  background-color: #F3F1EE;
}

* {
  font-family: Calibri, 'Gill Sans', 'Gill Sans MT', 'Trebuchet MS', sans-serif;
  font-size: x-large;
}

#root {
  height: 100%;
}

.SLTEigengrauApp {
  text-align: normal;
  min-width:320px;
  height: 100%;
}

.slt_background {
  background-position: center;
  background-size: cover;
  background: url(./slt_gray_embed/SLT_StonyBackground_700x700.jpg);
  background-repeat: repeat;
  height: 100%;
}

.slt_background_overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.85);
}

/** Start SLT Header CSS **/
.SLT-Header {
  display: float;
  background-color: #F3F1EE;
  border: 0em;
  margin: 0em;
  height: 3.2em;
  padding: 0em;
  color: rgb(0, 0, 0);
  border-bottom: 3px solid rgba(50,50,50,.5);
  min-width:775px;
}

.SLT-Top-Left-Menu-Bar {
  display: inline-block;
  position: absolute;
  left: .5em;
  top: .25em;
  vertical-align: bottom;
  padding-left: 0;
  margin-left: 0;
  color: rgb(0, 0, 0);
  background-color: #F3F1EE;
  border: 0em;
  margin: 0em;
  height: 2.5em;
}

.SLT-Top-Left-Menu-Bar a:link, a:visited, a:hover, a:active {
  color: rgb(0, 0, 0);
  font-style: normal;
  text-decoration: none;
 /* height: 2em;
  vertical-align: bottom;*/
}

.SLT-Top-Left-Menu-Bar-Item a:link {
  display: inline-block;
  text-align: left;
  vertical-align: bottom;
  width: 100%;
  margin-top: 1.5em;
  height: 1em;
  font-size: x-large;
  text-decoration: none;
}

.SLT-Top-Left-Menu-Bar-Button {
  display: inline-block;
  align-content: left;
  vertical-align: bottom;
  background-color: #F3F1EE;
  color: rgb(0, 0, 0);
  font-style: normal;
  font-size: x-large;
  text-decoration: none;
  border: none;
  border-radius: none;
  margin: none;
  height: 1em;
  margin-top: 1.35em;
  margin-right: .5em;
}

.SLT-Top-Left-Menu-Bar-Button:focus{
  outline: none;
}

.SLT-Top-Left-Menu-Bar-Icon {
  display: inline-block;
  align-content: right;
  vertical-align: top;
  width: 46px;
  background-color: #16161D;
  border: none;
  border-radius: .3em;
  margin-right: 1em;
}

.SLT-Top-Left-Menu-Bar-Icon img {
  display: block;
  height: 1em;
}

.SLT-App-Logo img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0em;
  height: 3em;
  padding-top: .2em;
}

.SLT-Top-Right-Menu-Bar {
  display: inline-block;
  position: absolute;
  right: .5em;
  top: .25em;
  vertical-align: bottom;
  padding-right: 0;
  margin-right: 0;
  color: rgb(250, 250, 250);
  background-color: #FAF0E6;
  border: 0em;
  margin: 0em;
  height: 2.5em;
}

.SLT-Top-Right-Menu-Bar a:link, a:visited, a:hover, a:active {
  color: rgb(0, 0, 0);
  font-style: normal;
  text-decoration: none;
  /*height: 2em;
  vertical-align: bottom;*/
}

.SLT-Top-Right-Menu-Bar-Item a:link {
  display: inline-block;
  text-align: right;
  vertical-align: bottom;
  width: 100%;
  height: 1em;
  font-size: x-large;
  margin-top: 1.5em;
  text-decoration: none;
}

.SLT-Top-Right-Menu-Bar-Button {
  display: inline-block;
  align-content: right;
  vertical-align: bottom;
  background-color: #F3F1EE;
  color: rgb(0, 0, 0);
  font-style: normal;
  font-size: x-large;
  text-decoration: none;
  border: none;
  border-radius: none;
  height: 1em;
  margin-top: 1.35em;
  margin-left: .5em;
}

.SLT-Top-Right-Menu-Bar-Button:focus{
  outline: none;
}

.SLT-Top-Right-Menu-Bar-Icon {
  display: inline-block;
  align-content: right;
  vertical-align: top;
  width: 46px;
  background-color: #F3F1EE;
  border: none;
  border-radius: .3em;
  margin-right: 1em;
}

.SLT-Top-Right-Menu-Bar-Icon img {
  display: block;
  height: 1em;
}

/** End SLT Header CSS **/

/** Start SLT Footer CSS **/
.SLT-Footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
/*  display: block;
  clear: left; */
  text-align: center;
  padding: .1em;
/**  background-color: #F3F1EE; **/
  color: rgb(0, 0, 0);
}
/** End SLT Footer CSS **/

.slt_button {
  display: inline-block;
  align-content: left;
  vertical-align: top;
  width: 46px;
  /*  height: 1.80em; */
  background-color: #16161d;
  border: none;
  border-radius: .3em;
  margin-right: 1em;
}

.slt_button_clear {
  display: inline-block;
  align-content: center;
  vertical-align: top;
  height: 1em;
  width: 100%;
  background-color: transparent;
  border: none;
  border-radius: .3emem;
}

.slt_button_clear:focus{
  outline: none;
}

.slt_button img {
  display: block;
  height: 1em;
}

.slt_button_clear img {
  display: center;
/*  height: 1.7em;*/
  height: 1em;
  align-content: center;
}

.SLTEigengrauPage {
  clear: left;
  text-align: center;
  font-family: Calibri, 'Gill Sans', 'Gill Sans MT', 'Trebuchet MS', sans-serif;
  font-size: x-large;
  padding: none;
  height: 100%;
/*  padding-bottom: .5em;
  padding-top: .5em; */
}

a:link, a:visited, a:hover, a:active {
  color: rgb(0, 0, 0);
  font-family: Calibri, 'Gill Sans', 'Gill Sans MT', 'Trebuchet MS', sans-serif;
  font-size: large;
}

.TabBar_Table {
  width: 100%;
  padding: .1em;
  margin-top: .5em;
/*  background-color: #16161d;*/
  background-color: none;
  background-color: rgba(50,50,50,.75);
  color: rgb(250, 250, 250);
  height: 2.5em;
  width: 100%;
  align-content: center;
  border-bottom-width:1px;
  border-bottom-style: solid;
  border-bottom-color: rgb(250, 250, 250);
}

.TabBar_Col {
  display: table-cell;
  text-align: center;
  border: none;
  padding: .25em;
  margin: none;
  width: 33%;
}

.slt_tabbar_button {
  display: inline-block;
  padding: none;
  vertical-align: top;
  margin-left: 0em;
  height: 1.7em;
}

.TabBarButton_NotSelected {
  padding-top: none;
  padding-bottom: none;
  display: inline-block;
/*  background-color: #16161d; */
  background-color: none;
  color: rgb(250, 250, 250);
  width: 100%;
}

.TabBarButton_NotSelected:focus {
  outline: none;
}

.TabBarButton_NotSelected a {
  padding: none;
  border-style: none;
  margin: none;
  display: inline-block;
/*  background-color: #16161d; */
  background-color: none;
  color: rgb(250, 250, 250);
  width: 100%;
  font-style: normal;
  text-decoration: none;
  font-family: Calibri, 'Gill Sans', 'Gill Sans MT', 'Trebuchet MS', sans-serif;
  font-size: large;
}

.TabBarButton_Selected {
  padding-top: .1em;
  padding-bottom: .1em;
  padding-left: 0em;
  padding-right: 0em;
  margin: none;
  border-style: none;
  border-color: #16161d;
  display: inline-block;
  background-color: rgb(250, 250, 250);
  color: #16161d;
  width: 100%;
}

.TabBarButton_Selected a {
  padding: 0em;
  margin: 0em;
  border-style: none;
  font-style: normal;
  text-decoration: none;
  display: inline-block;
  background-color: rgb(250, 250, 250);
  color: #16161d;
  width: 100%;
  font-family: Calibri, 'Gill Sans', 'Gill Sans MT', 'Trebuchet MS', sans-serif;
  font-size: large;
}

.TabBarButton_Selected:focus {
  outline: none;
}

.Page_Table {
  width: 100%;
  padding: 1em;
/**  background-color: #FAF0E6; **/
  color: #16161d;
  height: 2em;
  align-content: center;
  border-collapse: collapse;
  padding-bottom: .5em;
}

.Page_Row_Label {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  text-align: right;
  width: 90%;
  margin-top: .5em;
}

.Page_Row_Label InputLabel {
  width: 100%;
}

.Page_Col {
  border: none;
  padding-top: .5em;
  width: 100%;
}

.Edit_Ctl {
  display: center;
  border-radius: .3em;
  color: #16161d;
  background-color: rgb(250, 250, 250);  
  width: 70%;
  height: 100%;
  margin-left: 5px;
}

.IP_Ctl {
  display: right;
  align-content: right;
  border-radius: .3em;
  color: #16161d;
  background-color: rgb(250, 250, 250);
  width: 80%;
}

.Msg_Ctl_Ok {
  display: inline-block;
  margin-left: .25em;
  width: 92%;
  height: 1em;
  text-align: left;
  border-radius: .3em;
  color: rgb(65, 65, 65);
  padding: .2em;
  padding-left: .5em;
  background-color: rgb(209, 252, 209);
  margin-bottom: .2em;
  margin-top: .2em;
}

.Msg_Ctl_Err {
  display: inline-block;
  margin-left: .25em;
  width: 92%;
  height: 1em;
  text-align: left;
  border-radius: .3em;
  color: rgb(65, 65, 65);
  padding: .2em;
  padding-left: .5em;
  background-color: rgb(252, 209, 209);
  margin-bottom: .2em;
  margin-top: .2em;
}

.InfoRunBar {
  display: inline-block;
  margin-left: .25em;
  width: 92%;
  height: 1em;
  text-align: left;
  border-radius: .3em;
  color: rgb(65, 65, 65);
  padding: .2em;
  padding-left: .5em;
  background-color: rgba(0, 255, 51, 0.233);
  margin-bottom: .2em;
  margin-top: .2em;
}

.ErrorRunBar {
  display: inline-block;
  margin-left: .25em;
  width: 92%;
  height: 1em;
  text-align: left;
  border-radius: .3em;
  color: rgb(65, 65, 65);
  padding: .2em;
  padding-left: .5em;
  background-color: rgb(255, 200, 200);
  margin-bottom: .2em;
  margin-top: .2em;
}

.Submit_Ctl {
  display: center;
  border-radius: .25em;
  color: rgb(250, 250, 250);  
  background-color: #16161d;
  width: 50%;
  margin-top: 1em;
  margin-bottom: 1em;
}

.Submit_Ctl:focus {
  outline: none;
}

.ConfigDeviceTableCol {
/*  display: inline-block;*/
  width: 21.3%;
  text-align: left;
  padding-top: .2em;
  padding-left: .2em;
}

.ConfigDeviceLaunchButtonTableCol {
/*  display: inline-block;*/
  align-content: center;
  width: 12%;
  min-width: 50px;
  vertical-align: top;
  padding-top: 3.5px;
/*  padding-bottom: 4px;*/
}

.ConfigureDevicesHeader {
  display: inline-block;
  text-align: left;
  width: 80%;
  padding-top: 1em;
  padding-bottom: 0em;
  margin-bottom: 0em;
  height: 1.25em;
  background-color: #FAF0E6;
}

.ConfigureDevicesHeaderLabel {
  width: 100%;
}

.ConfigureDevicesHeader Button {
  float: right;
}

.ConfigureDevicesHeaderAddButton {
  height: 1.25em;
  width: 12.4%;
  align-content: center;
  background-color: rgb(250, 250, 250);
  border: 1px #16161d;
  border-style: solid;
  border-top-left-radius: .3em;
  border-top-right-radius: .3em;
  border-bottom: 0px;
  margin-top: 0em;
  margin-left: 0em;
  margin-right: 0em;
  margin-bottom: 0em;
  padding-bottom: 0em;
  border-collapse: collapse;
}

.ConfigureDevicesHeaderAddButton img {
  display: center;
  height: 1em;
  margin-bottom: 0em;
  padding-bottom: 0em;
  vertical-align: text-top;
}

.FullTableCol {
  display: center;
  padding-top: .2em;
  padding-left: .2em;
}

.SelectTable {
  margin-top: 1em;
  margin-bottom: 1em;
  width: 80%;
}

.SelectTable td {
  width: 100%;
  border: 1.2px solid #16161d;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 1.2em;
  border-collapse: collapse;
}

.PropertyTable {
  width: 80%;
  display: center;
  padding: .1em;
  background-color: rgb(250, 250, 250);
  color: #16161d;
  height: 2em;
  align-content: center;
  border-collapse: collapse;
  margin-top: 1em;
}

.PropertyTableHeader {
  background: #16161d;
  color: rgb(250, 250, 250);
}

.PropertyTable td {
  border: 1.2px solid #16161d;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 1.2em;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
}

.PropertyTable .Edit_Ctl {
  padding: 0em;
  margin: 0em;
  width: 95%;
  align-content: center;
  vertical-align: baseline;
  border: none;
}

.Property_Name_Col {
  width: 30%;
}

.Property_Value_Col {
  width: 60%;
}

.Property_Action_Col {
  width: 10%;
}

#remove_property_button:focus {
  outline: none;
}

.formActionButton {
  width: 50%;
  align-content: center;
  margin-top: 1em;
  margin-bottom: 1em;
  background-color:  #16161d;
  color:rgb(250, 250, 250);
  border-radius: .3em;
}

.Page_Table tr td .deviceActionButton {
  display: inline-block;
  width: 50%;
}

.RunListTable {
  margin-top: 0em;
  align-content: center;
  width: 95%;
  table-layout: fixed;
  border-collapse: collapse;
  border-top-left-radius: .3em;
  border-bottom-left-radius: .3em;
  border-bottom-right-radius: .3em;
}

.ImageDataNulls {
  margin-top: 0em;
  align-content: center;
  width: 95%;
  table-layout: fixed;
  border-collapse: collapse;
  border-top-left-radius: .3em;
  border-bottom-left-radius: .3em;
  border-bottom-right-radius: .3em;
}

.RunListTable .tableNavBar .PageNavBar {
  border: unset;
  border-radius: unset;
  border-top-left-radius: unset;
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
  table-layout: unset;
}

.RunListTable td {
  border: 1.2px solid #16161d;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 1.25em;
  border-collapse: collapse;
}

.ImageDataNulls td {
  border: 1.2px solid #16161d;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 1.25em;
  border-collapse: collapse;
}

.RunListTable tr {
  margin: 0em;
  padding: 0em;
  min-width: 75px;
}

.ImageDataNulls tr {
  margin: 0em;
  padding: 0em;
  min-width: 75px;
}

.RunListTable tr:nth-child(even) {
  background: #ccc;
}

.ImageDataNulls tr:nth-child(even) {
  background: #ccc;
}
.RunListTableCol  {
/*  display: inline-block;*/
  width: 21.3%;
  text-align: left;
  padding-top: .2em;
  padding-left: .2em;
  cursor: pointer;
}

.RunListLabelTableCol {
  /*  display: inline-block;*/
    width: 35%;
    text-align: left;
    padding-top: .2em;
    padding-left: .2em;
    color: #005CF5;
    text-decoration: underline;
    cursor: pointer;
}

.RunListLabelTableColHeader {
  /*  display: inline-block;*/
    width: 35%;
    font-size: 125%;
    text-align: left;
    vertical-align: bottom;
/*    padding-top: .2em;*/
    padding-left: .2em;
    padding-bottom: .2em;
    background: #333333;
    color: #FAFAFA;
}

.RunListDescriptionTableCol {
    /*  display: inline-block;*/
      width: 50%;
      text-align: left;
      padding-top: .2em;
      padding-left: .2em;
}

.RunListDateTimeTableCol {
  width: 10%;
  text-align: left;
  padding-top: .2em;
  padding-left: .2em;
}

.RunListDescriptionTableColHeader {
  /*  display: inline-block;*/
    width: 50%;
    font-size: 125%;
    text-align: left;
    vertical-align: bottom;
/*    padding-top: .2em;*/
    padding-left: .2em;
    padding-bottom: .2em;
    background: #333333;
    color: #FAFAFA;
}
  
.RunDetailLaunchButtonTableCol {
/*  display: inline-block;*/
  align-content: center;
  width: 2em;
  min-width: 50px;
  vertical-align: top;
  padding-top: 3.5px;
/*  padding-bottom: 4px;*/
  cursor: pointer;
}

.RunDetailLaunchButtonTableColHeader {
  /*  display: inline-block;*/
    align-content: bottom;
    text-align: center;
    width: 2em;
    height: 100%;
    min-width: 50px;
    vertical-align: bottom;
/*    padding-top: 3.5px;*/
  /*  padding-bottom: 4px;*/
    background: #333333;
    color: #FAFAFA;
    padding: none;
    margin: none;
}

.RunDetailLaunchButtonTableColHeader img {
  display: center;
/*  height: 1.7em;*/
  height: 100%;
  align-content: bottom;
  padding: none;
  margin: none;
}

.RunListHeader {
  display: inline-block;
  text-align: center;
  font-size: 150%;
  width: 80%;
  padding-top: .25em;
  padding-bottom: .1em;
  margin-bottom: 0em;
}

.PageNavTableHeaderRowColumn {
  /*  display: inline-block;*/
  width: 15%;
  font-size: 125%;
  text-align: left;
  vertical-align: bottom;
/*    padding-top: .2em;*/
  padding-left: .2em;
  padding-bottom: .2em;
  background: #333333;
  color: #FAFAFA;
}

.ProfileHeader {
  display: inline-block;
  text-align: center;
  font-size: 150%;
  width: 80%;
  padding-top: .25em;
  padding-bottom: .1em;
  margin-bottom: 0em;
}

.RunListHeaderLabel {
  width: 100%;
}

.Filter_Col {
  display: table-cell;
  border: none;
  padding-top: .5em;
  width: 45%;
}

.RunDetailTitle {
  display: inline-block;
  text-align: center;
  font-size: 150%;
  width: 80%;
  padding-top: .25em;
  padding-bottom: .1em;
  margin-bottom: .25em;
}

.RunDetailTitleLabel {
  width: 100%;
}

.Filter_Ctl {
  display: center;
  border-radius: .3em;
  color: #16161d;
  background-color: rgb(250, 250, 250);  
}

.VNAImageSnapshot {
  display: inline-block;
  align-content: center;
  width: 90%;
}

.VNAImageNavButton {
  display: inline-block;
  align-content: center;
  width: 10%;
  min-width: 50px;
  min-height: 1em;
  padding-top: .1em;
}

.VNAImageNavButton .slt_button_clear:focus {
  outline: none;
}

.VNAImageNavButton .slt_button_clear {
  width: 20%;
  min-width: 50px;
  vertical-align: top;
  padding-top: 0px;
}

.VNAImagePositionMsg {
  align-content: center;
  vertical-align: bottom;
}

.VNAScreenNavBar {
  margin-top: .5em;
}

.VNAScreenFilterTable {
  width: 90%;
  margin: .5em;
}

.AppLogTitle {
  display: inline-block;
  text-align: center;
  font-size: 150%;
  width: 80%;
  padding-top: .25em;
  padding-bottom: .1em;
  margin-bottom: .25em;
}

.AppLogTitleLabel {
  width: 100%;
}

.AppLogTable {
  margin-top: 0em;
  align-content: center;
  width: 80%;
  table-layout: fixed;
  border-collapse: collapse;
  border-top-left-radius: .3em;
  border-bottom-left-radius: .3em;
  border-bottom-right-radius: .3em;
}

.AppLogTable .tableNavBar .PageNavBar {
  border: unset;
  border-radius: unset;
  border-top-left-radius: unset;
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
  table-layout: unset;
}

.AppLogTable td {
  border: 1.2px solid #16161d;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 1.2em;
  border-collapse: collapse;
}

.AppLogTable tr {
  margin: 0em;
  padding: 0em;
  min-width: 75px;
}

.AppLogDatetimeTableCol {
  width: 20%;
  text-align: left;
  padding-top: .2em;
  padding-left: .2em;
}

.AppLogCodeTableCol {
  width: 8%;
  text-align: left;
  padding-top: .2em;
  padding-left: .2em;
}

.AppLogMessageTableCol {
  width: 70%;
  text-align: left;
  padding-top: .2em;
  padding-left: .2em;
}

#VersionTableId {
  margin-bottom: .5em;
}