/**
 ** Basic settings
 **/
:root {
    --sltnavbar-bg-color: #F3F1F0; /* hsl(0, 0%, 15%); */
    --sltnavbar-text-color: rgba(12, 12, 29, .7); /* #16161d; /* rgba(50,50,50,.75); hsl(0, 0%, 75%); */
    --sltnavbar-text-color-focus: white;
    --sltnavbar-bg-contrast: rgba(50, 50, 50, .75); /* hsl(0, 0%, 25%); */
    --sltnavbar-toggle-focus: rgba(120, 120, 120, .75);
}

a:link, a:visited, a:hover, a:active {
    color: var(--sltnavbar-text-color);
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    height: 100vh;
    font-family: Calibri, 'Gill Sans', 'Gill Sans MT', 'Trebuchet MS', sans-serif;
    line-height: 1.5em;
}

.sltcontainer {
    max-width: 1400px;
    padding: 0 1.4rem;
    margin: 0 auto;
}

#sltnavbar {
    --sltnavbar-height: 40px;
    position: fixed;
    height: var(--sltnavbar-height);
/**    background-color: var(--sltnavbar-bg-color); **/
    left: 0;
    right: 0;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

.sltnavbar-container {
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;
}

.sltnavbar-item {
    margin: 0.4em;
    width: 100%;
}

.slthome-link,
.sltnavbar-link {
    color: var(--sltnavbar-text-color);
    text-decoration: none;
    display: flex;
    font-weight: 500;
    margin: .4em;
    align-items: center;
}

.sltnavbar-link a :visited {
    color: var(--sltnavbar-text-color);
}

.slthome-link:is(:focus, :hover) {
    color: var(--sltnavbar-toggle-focus);
}

.sltnavbar-link {
    justify-content: center;
    width: 100%;
    padding: 0.4em, 0.8em;
    border-radius: 5px;
}

.sltnavbar-link:is(:focus, :hover) {
    color: var(--sltnavbar-text-color-focus);
    background-color: var(--sltnavbar-bg-contrast);
}

.sltnavbar-logo {
    width: 30px;
    height: 30px;
    margin-top: .1em;
    margin-inline-start: 0.25em;
    margin-inline-end: .75em;
    text-align: right;
}

#sltnavbar-toggle {
    cursor: pointer;
    border: none;
    background-color: transparent;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.slticon-bar {
    display: block;
    width: 25px;
    height: 4px;
    margin: 2px;
    background-color: var(--sltnavbar-text-color);
}

#sltnavbar-toggle:is(:focus, :hover) .slticon-bar {
    background-color: var(--sltnavbar-toggle-focus);
}

/** animate hamburger icon to become close icon logic in CSS **/
#sltnavbar-toggle[aria-expanded='true'] .slticon-bar:is(:first-child, :last-child) {
    position: absolute;
    margin: 0;
    width: 30px;
}

#sltnavbar-toggle[aria-expanded='true'] .slticon-bar:first-child {
    transform: rotate(45deg);
}

#sltnavbar-toggle[aria-expanded='true'] .slticon-bar:nth-child(2) {
    opacity: 0;
}

#sltnavbar-toggle[aria-expanded='true'] .slticon-bar:last-child {
    transform: rotate(-45deg);
}

#sltnavbar-menu {
    position: fixed;
    top: var(--sltnavbar-height);
    bottom: 0;
    opacity: 0;
    visibility: hidden;
    left: 0;
    right: 0;
}

#sltnavbar-toggle[aria-expanded='true'] + #sltnavbar-menu {
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 1;
    visibility: visible;
}

.sltnavbar-links {
    list-style: none;
    position: absolute;
    background-color: var(--sltnavbar-bg-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    left: 0;
    right: 0;
    margin: 1.4em;
    border-radius: 5px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}

#sltnavbar-toggle[aria-expanded='true'] + #sltnavbar-menu .sltnavbar-links {
    padding: 1em;
}

/** Handle toggle to full desktop menu at 833 px width **/
@media screen and (min-width: 833px) {
    #sltnavbar-toggle,
    #sltnavbar-toggle[aria-expanded='true'] {
        display: none;
    }

    #sltnavbar-menu,
    #sltnavbar-toggle[aria-expanded='true'] + #sltnavbar-menu {
        visibility: visible;
        opacity: 1;
        position: static;
        display: block;
/*        width: 90%;*/
        height: 100%;
    }

    .sltnavbar-links,
    #sltnavbar-toggle[aria-expanded='true'] + #sltnavbar-menu .sltnavbar-links .sltnavbar-logo {
        margin: 0;
        padding: 0;
        background-color: transparent;
        box-shadow: none;
        position: static;
        flex-direction: row;
        width: 100%;
        height: 100%;
    }
}